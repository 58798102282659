import { CustomThemeTypings, ComponentStyleConfig } from "@chakra-ui/react";

export const theme: CustomThemeTypings = {
  colors: {
    theme: {
      primary: "#805AD5",
      accent: "#3182CE",
      bg: "#E2E8F0",
    },
  },
  zIndices: {
    tooltip: 10000, // fullcalendarと被るので
    loader: 10001,
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: "gray.300",
            bgColor: "white",
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          bgColor: "white",
        },
      },
    },
    Button: {
      variants: {
        outline: {
          bgColor: "white",
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bgColor: "white",
          },
        },
      },
    },
    Table: {
      variants: {
        simple: {
          th: {
            borderColor: "gray.300",
          },
          td: {
            borderColor: "gray.300",
          },
        },
      },
    },
    Card: {
      variants: {
        outline: {
          container: {
            borderColor: "gray.300",
            borderRadius: "12px",
            bgColor: "white",
            p: 6,
            rowGap: 4,
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            fontWeight: "600",
          },
        },
      },
    },
  } satisfies Record<string, ComponentStyleConfig>,
  styles: {
    global: {
      // TableContainer内でComponentStyleConfigの実装がないため、classを指定してデフォルトスタイルを設定する
      ".chakra-table__container": {
        borderColor: "gray.300",
        bgColor: "white",
      },
      ":root": {
        "--sidebar-width": "176px",
      },
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};
