import { useToast as useChakraToast, UseToastOptions } from "@chakra-ui/react";

export const useToast = (options?: UseToastOptions) => {
  return useChakraToast({
    duration: 9000,
    position: "bottom-left",
    isClosable: true,
    containerStyle: {
      whiteSpace: "pre-wrap",
    },
    ...options,
  });
};
