"use client";
import {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useReducer,
  Reducer,
  useMemo,
  useEffect,
} from "react";
import { gql, useApolloClient } from "@apollo/client";

import {
  getAccessToken,
  getRefreshToken,
  updateAccessToken,
  updateRefreshToken,
} from "~/apis/apolloClient";

import {
  useLoginMutation,
  useRefreshMutation,
  useLogoutMutation,
} from "./index.generated";
import { useToast } from "~/src/hooks/useToast";

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(loginAccountInput: { email: $email, password: $password }) {
      accessToken
      refreshToken
      account {
        companyId
        name
      }
    }
  }
`;
const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout
  }
`;
const REFRESH_MUTATION = gql`
  mutation Refresh {
    refreshToken {
      accessToken
      refreshToken
      account {
        companyId
        name
      }
    }
  }
`;

const REFRESH_INTERVAL_IN_MS = 15 * 60 * 1000;

type State = {
  loggedIn: boolean;
};
type Action =
  | {
      type: "refresh";
    }
  | {
      type: "login";
    }
  | {
      type: "logout";
    };

const initialState: State = {
  loggedIn: false,
};

type AuthContextType = State & {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({
  loggedIn: false,
  login: async () => {
    console.log("Not implemented");
    throw new Error("Not implemented");
  },
  logout: async () => {
    console.log("Not implemented");
    throw new Error("Not implemented");
  },
  refresh: async () => {
    console.log("Not implemented");
    throw new Error("Not implemented");
  },
});
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    useCallback((state, action) => {
      switch (action.type) {
        case "login": {
          return {
            ...state,
            loggedIn: true,
          };
        }
        case "refresh": {
          return {
            ...state,
            loggedIn: true,
          };
        }
        case "logout": {
          return {
            ...state,
            loggedIn: false,
          };
        }
        default: {
          return state;
        }
      }
    }, []),
    initialState,
  );
  const [login] = useLoginMutation();
  const [refresh] = useRefreshMutation();
  const [logout] = useLogoutMutation();

  const toast = useToast();

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      const { data } = await login({
        variables: {
          email,
          password,
        },
      });
      if (data?.login) {
        updateAccessToken(data.login.accessToken);
        updateRefreshToken(data.login.refreshToken);
        dispatch({ type: "login" });
      }
    },
    [login],
  );

  const { cache } = useApolloClient();

  const handleLogout = useCallback(async () => {
    try {
      await logout().catch((e) => {});
      updateAccessToken(null);
      updateRefreshToken(null);
      cache.reset();
      dispatch({ type: "logout" });
    } catch (e) {
      // console.error(e);
    }
  }, [logout, cache]);

  const handleRefresh = useCallback(async () => {
    const refreshToken = getRefreshToken();
    const { data } = await refresh({
      context: {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
      },
    });
    if (data?.refreshToken) {
      updateAccessToken(data.refreshToken.accessToken);
      updateRefreshToken(data.refreshToken.refreshToken);
      dispatch({ type: "refresh" });
    }
  }, [refresh]);

  useEffect(() => {
    const timerId = setInterval(async () => {
      try {
        if (!state.loggedIn) return;
        await handleRefresh();
      } catch (e) {
        // TODO: Handle error
        console.log(e);
        handleLogout();
      }
    }, REFRESH_INTERVAL_IN_MS);
    return () => {
      clearInterval(timerId);
    };
  }, [refresh, handleLogout, state.loggedIn, handleRefresh]);
  useEffect(() => {
    if (getAccessToken()) {
      dispatch({ type: "login" });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      login: handleLogin,
      logout: handleLogout,
      refresh: handleRefresh,
    }),
    [state, handleLogin, handleLogout, handleRefresh],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
