import React, { Fragment, useMemo } from "react";
import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  BoxProps,
  FlexProps,
  Divider,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
import {
  MdBusiness,
  MdOutlineBadge,
  MdOutlineLocalShipping,
  MdOutlineWorkOutline,
  MdOutlineReceiptLong,
  MdOutlinePayments,
  MdOutlineSettings,
  MdOutlineEditCalendar,
  MdOutlineChecklistRtl,
} from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { BiMapPin } from "react-icons/bi";
import { Link } from "@chakra-ui/next-js";
import { Route } from "next";
import { usePathname } from "next/navigation";

type LinkItemProps = {
  name: string;
  icon: IconType;
  link?: Route;
  associatedLink?: string;
};
type LinkGroupProps = {
  groupName?: string;
  linkItems: Array<LinkItemProps>;
};
const adminLinkGroups: Array<LinkGroupProps> = [
  {
    groupName: "マスタ管理",
    linkItems: [
      {
        name: "取引先管理",
        icon: MdBusiness,
        link: "/customer",
        associatedLink: "/partner",
      },
      { name: "配送員管理", icon: MdOutlineBadge, link: "/deliver" },
      { name: "車両管理", icon: MdOutlineLocalShipping, link: "/vehicle" },
      { name: "案件管理", icon: MdOutlineWorkOutline, link: "/project" },
      { name: "マップビュー", icon: BiMapPin, link: "/map-view" },
    ],
  },
  {
    groupName: "稼働管理",
    linkItems: [
      { name: "シフト管理", icon: MdOutlineEditCalendar, link: "/shift" },
      { name: "稼働実績", icon: MdOutlineChecklistRtl, link: "/attendance" },
    ],
  },
  {
    groupName: "会計管理",
    linkItems: [
      { name: "請求情報", icon: MdOutlineReceiptLong, link: "/invoice" },
      { name: "支払情報", icon: MdOutlinePayments, link: "/payment" },
      {
        name: "経営管理(β)",
        icon: GoGraph,
        link: "/dashboard",
      },
    ],
  },
  {
    linkItems: [
      {
        name: "会社設定",
        icon: MdOutlineSettings,
        link: "/settings",
      },
    ],
  },
];
const guestLinkGroups: Array<LinkGroupProps> = [
  {
    linkItems: [
      {
        name: "ログイン",
        icon: MdBusiness,
        link: "/signin",
      },
    ],
  },
];
const leaderLinkGroups: Array<LinkGroupProps> = [
  {
    groupName: "稼働管理",
    linkItems: [
      {
        name: "シフト管理",
        icon: MdOutlineEditCalendar,
        link: "/shift",
      },
    ],
  },
];

const superAdminLinkGroups: Array<LinkGroupProps> = [
  {
    linkItems: [
      {
        name: "会社管理",
        icon: () => <></>,
        link: "/admin/company",
      },
      {
        name: "アカウント管理",
        icon: () => <></>,
        link: "/admin/account",
      },
    ],
  },
];

type SidebarContentProps = BoxProps &
  SidebarProps & {
    currentPath: string;
  };

const SidebarContent = ({
  variant,
  currentPath,
  ...rest
}: SidebarContentProps) => {
  const linkGroups = useMemo(() => {
    switch (variant) {
      case "default":
        return adminLinkGroups;
      case "shift_only":
        return leaderLinkGroups;
      case "guest":
        return guestLinkGroups;
      case "super_admin":
        return superAdminLinkGroups;
      default:
        return adminLinkGroups;
    }
  }, [variant]);

  return (
    <Box
      minW={44}
      pt={2}
      bg="gray.600"
      borderRight="1px"
      borderRightColor="gray.300"
      w={44}
      h="full"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      zIndex={1}
      {...rest}
    >
      {linkGroups.map((group, i) => (
        <Fragment key={`${group.groupName}-${i}`}>
          {i === 0 ? null : (
            <Box px={2} py={3}>
              <Divider />
            </Box>
          )}
          {group.groupName ? (
            <Box px={2} py={1}>
              <Text
                fontFamily="Inter"
                fontSize="14px"
                fontWeight="bold"
                color="white"
                lineHeight="20px"
              >
                {group.groupName}
              </Text>
            </Box>
          ) : null}
          {group.linkItems.map((item) => (
            <NavItem
              key={item.name}
              icon={item.icon}
              link={item.link}
              active={
                (item.link && currentPath.startsWith(item.link)) ||
                (item.associatedLink !== undefined &&
                  currentPath.startsWith(item.associatedLink))
              }
            >
              {item.name}
            </NavItem>
          ))}
        </Fragment>
      ))}
    </Box>
  );
};

type NavItemProps = FlexProps & {
  icon: IconType;
  children: string;
  active?: boolean;
  link?: Route;
};
const NavItem = ({
  icon,
  children,
  active,
  link = "/",
  ...rest
}: NavItemProps) => {
  return (
    <>
      <Link
        href={link}
        style={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
      >
        <Flex
          align="center"
          justifyContent="flex-start"
          gap={2}
          pl={6}
          py={3}
          direction="row"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "gray.700",
            color: "white",
          }}
          bg={active ? "gray.700" : "gray.600"}
          textColor={useColorModeValue("white", "white")}
          {...rest}
        >
          {icon && (
            <Icon
              fontSize="24"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          <Text lineHeight={6} size="md">
            {children}
          </Text>
        </Flex>
      </Link>
    </>
  );
};
type SidebarProps = {
  variant: "default" | "shift_only" | "guest" | "super_admin";
};
const Sidebar = ({ variant }: SidebarProps) => {
  const currentPath = usePathname();
  return (
    <SidebarContent currentPath={currentPath} as="nav" variant={variant} />
  );
};
export default Sidebar;
